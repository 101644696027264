import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <div className="contact-method">
                    <span className="icon alt fa-envelope"></span>
                    <h3>Email</h3>
                    <a href="mailto:mail@angelatuscher.com">mail@angelatuscher.com</a>
                </div>
            </section>
            <section>
                <div className="contact-method">
                    <span className="icon alt fa-phone"></span>
                    <h3>Telefon</h3>
                    <a href="tel:+491729252702">+49 172 925 2702</a>
                </div>
            </section>
        </div>
    </section>
)

export default Contact
