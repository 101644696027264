import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="https://de-de.facebook.com/pages/category/Athlete/Angela-Mariana-Tuscher-625564937584118/" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/anschetti/" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; 2020 Angela M. Tuscher</li><li>Design: <a href="https://html5up.net">HTML5 UP</a></li><li><Link to="/privacy">Impressum & Datenschutz</Link></li>
            </ul>
        </div>
    </footer>
)

export default Footer
